import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {

  transform(image: string): string {
    const low = '120';
    const medium = '220';
    const high = '320';
    const ultra = '515';
    const imageSizeConfig = [low, medium, high, ultra];
    let template:string = '';
    imageSizeConfig.forEach((element, index, arr) => {
      const isLastElement = index === arr.length - 1 ? true : false;
      template += this.replaceImage(image, element, isLastElement);  
    });
    return template;
  }

  replaceImage(image:string, size: string, isLastElement: boolean = false){
    const paddingDefaultSize = 'SF226,226';
    const imageDefaultSize = 'AA210';
    const replaced = image.replace(paddingDefaultSize, `SF${size},${size}`).replace(imageDefaultSize, `AA${size}`);
    const isCommaPresent = isLastElement ? '' : ','; 
    const result = `${replaced} ${size}w${isCommaPresent}`;
    return result;
  }

}
